export default {
    state: {
        newsStatus: null,
        countNews: 0
    },
    mutations: {
        setStatusNews(state, status) {
            state.newsStatus = status;
        },
        setCountNews(state, count) {
            state.countNews = count
        }
    },
    getters: {},
};