<template>
  <div
    v-if="
      $store.state.company.companyList &&
      $store.state.company.companyList.length
    "
  >
    <div v-if="$route.name !== 'TransactionsNew'">
      <div v-if="$store.state.company.companyList.length > 1">
        <Dropdown
          v-model="company"
          :options="compnayLiteList"
          optionLabel="full_name"
          placeholder="Выбрать компанию"
          dataKey="id"
          @change="onChange($event.value)"
          :filter="true"
          filterPlaceholder="Поиск"
          scrollHeight="400px"
          class="w-full"
          :panelStyle="{'width': 'calc(100vw-1rem)'}"
          ref="filterCompany"
          :disabled="$route.name != 'Home' && $route.name != 'OdinAss'"
        > 
          <template #footer>
            <div class="p-multiselect-footer bg-gray-300">
              <div
                class="flex align-items-center justify-content-end"
                style="padding: 0.75rem 1.25rem"
              >
                <Button
                  @click="this.$refs.filterCompany.hide()"
                  class="p-button-sm"
                  label="Закрыть"
                />
              </div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div v-else class="text-lg pt-1">
        {{
          $store.state.company.curCompany
            ? $store.state.company.curCompany.full_name
            : null
        }}
      </div>
    </div>
    <div class="flex align-items-center py-2" v-else>
      Данные указанные в отчете являются справочными.
    </div>
  </div>
  <Skeleton v-else width="100%" style="padding: 1.25rem 0"></Skeleton>

  <!-- <div v-else class="w-full" style="padding: 1.25rem 0"></div> -->
</template>
<script>
import store from "@/store";
import { setStoreCurrentCompany } from "@/api/company";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "FilterCompany",
  computed: {
    ...mapGetters(["company"]),
    compnayLiteList() {
      return store.state.company.companyList.map(function (el) {
        return {
          full_name: el.full_name,
          id: el.id,
        };
      });
    },
  },

  watch: {
    company(value) {
      mapGetters(["company"]);
    },
  },

  created() {
    // this.getCompanyList();
  },
  methods: {
    onChange(event) {
      // store.commit("setCurCompany", event);
      // this.getCompanyList();
      setStoreCurrentCompany(event["id"]);
    },
    ...mapActions(["getCompanyList"]),
    ...mapActions(["GET_COMPANY_LIST_FROM_API"]),
  },
  mounted() {
    this.GET_COMPANY_LIST_FROM_API();
  },
};
</script>

<style scoped>
</style>