<template>
  <!-- <Button
    class="p-button-sm p-button-raised px-2 w-full"
    icon="pi pi-megaphone"
    iconPos="left"
    badgeClass="p-badge-warning"
    v-tooltip.bottom="'Оповещение'"
    @click="() => $router.push('/news')"
  /> -->
  <Button
    style="height: 35px"
    class="flex gap-2 sber-btn w-full justify-content-center p-2 h-full shadow-2"
    @click="visible = true"
  >
    <img
      style="width: 20px; margin-right: 0 !important"
      src="../../public/static/img/sber-logo.svg"
    />
    <span class="hidden md:block"> Партнерская программа</span> </Button
  ><Dialog
    v-model:visible="visible"
    modal
    header="Партнерская программа СБЕР Бизнес"
    :style="{ width: '90rem' }"
    :breakpoints="{ '1199px': '75vw', '575px': '100vw' }"
  >
    <div class="pt-4">
      <div
        class="grid flex-column justify-content-center align-items-center gap-3 md:flex-row"
      >
        <div class="col-8 md:col-3 border-round shadow-2">
          <a
            target="_blank"
            class="border-round sber-ref w-full h-full block"
            href="https://partners.dasreda.ru/landing/credits?erid=2RanynEonqn&partnerID=12e0c3c4d5f6d72749d5"
          >
            <img
              class="w-full"
              src="../../public/static/img/referal-banners/creditsber.png"
              alt=""
              srcset=""
            />
          </a>
        </div>

        <div class="col-8 md:col-3 border-round shadow-2">
          <a
            target="_blank"
            class="border-round sber-ref w-full h-full block"
            href="https://partners.dasreda.ru/landing/products?partnerID=10009b629809e86b3045"
          >
            <img
              class="w-full"
              src="../../public/static/img/referal-banners/rborko.png"
              alt=""
              srcset=""
            />
          </a>
        </div>

        <div class="col-8 md:col-3 border-round shadow-2">
          <a
            target="_blank"
            class="border-round sber-ref w-full h-full block"
            href="https://partners.dasreda.ru/landing/self-employed?erid=2RanynAMpzk&partnerID=d1b9ef0070c248907eb7"
          >
            <img
              class="w-full"
              src="../../public/static/img/referal-banners/samozanity.png"
              alt=""
              srcset=""
            />
          </a>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script setup>
import { ref } from "vue";
const visible = ref(false);
const referalData = ref([
  {
    srcImg: "../../public/static/img/referal-banners/creditsber.png",
    link: "",
  },
  {
    srcImg: "../../public/static/img/referal-banners/creditsber.png",
    link: "",
  },
  {
    srcImg: "../../public/static/img/referal-banners/creditsber.png",
    link: "",
  },
]);
</script>

<style lang="scss">
.sber-btn {
  background-color: #107f8c !important;
  padding: 12px 24px;
  display: flex;
  justify-content: center;
  height: 40px;
  border: 0 !important;
  transition: all 0.2s ease;
  &::hover {
    border: 0 !important;
    background-color: #21a19a !important;
    background: #21a19a !important;
  }
  &:disabled {
    background-color: #90d0cc !important;
    background: #90d0cc !important;
  }
  img {
    width: 22px;
    margin-right: 8px;
  }
}
.sber-ref {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;


  &::after {
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 99; 
    opacity: 0;
    transition: opacity ease  .3s;
  } 
  &::before {
    content: "Подробнее";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 100; 
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity ease  .3s;
  }

  &:hover {
    &::before, &::after {
        opacity: 1;
    }
  }
}
</style>
