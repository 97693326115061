 export default {
     state: {
         filialInfo: {},
     },
     mutations: {
         setFilialInfo(state, info) {
             state.filialInfo = info;
         },
     },
     getters: {},
 };