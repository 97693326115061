import api from "@/api/index";
import store from "@/store";
import {
    setEmoji
} from "@/handlers/emoji";

export function getNewsStatus(that) {
    api
        .get("check_mail_read_status?company[]=0")
        .then((r) => {
            console.log(r.data);
            let status = r.data.is_unread_mails_exists
            store.commit('setStatusNews', status)
            let countNews = r.data.total_count
            store.commit('setCountNews', countNews)
        }).catch((err) => {
        console.log(err);
        let statusCode = err.request.status;
        let statusText = err.request.statusText;
        console.error(setEmoji("error") + statusCode + " " + statusText);
        switch (statusCode) {
            case 0:
                that.$toast.add({
                    severity: "warn",
                    summary: `${setEmoji("error")} Нет связи с сервером`,
                    detail: "Попробуйте позднее!",
                    life: 5000,
                });
                break;
            case 403:
                that.$toast.add({
                    severity: "warn",
                    summary: `${setEmoji("lock")} Время сессии истекло`,
                    detail: "Требуется авторизация",
                    life: 5000,
                });
                that.$router.push("/login");
                break;
            case 404:
                that.$toast.add({
                    severity: "error",
                    summary: `${setEmoji("error")} Ошибка ${statusCode}`,
                    detail: "Запрос не найден",
                    life: 5000,
                });
                break;
            default:
                that.$toast.add({
                    severity: "error",
                    summary: `${setEmoji("error")} Неизвестная ошибка ${statusCode}`,
                    detail: "Попробуйте позднее!",
                    life: 5000,
                });
                break;
        }
    });
}