<template>
  <router-view />
  <Toast baseZIndex="10010" position="bottom-right" />
</template>

<style lang="scss">
</style>
<script>
import store from "@/store";

export default {
  data() {
    return {};
  },
  methods: {},
  mounted() {
    console.log(localStorage); 
  },
  created() {
    document.title = window.appBaseTitle;
    (this.$primevue.config.locale.accept = "Ок"),
      (this.$primevue.config.locale.reject = "Отмена");
    this.$primevue.config.locale.weak = "Простой";
    this.$primevue.config.locale.medium = "Средний";
    this.$primevue.config.locale.strong = "Надежный";
    this.$primevue.config.locale.passwordPrompt = "Введите пароль";
    this.$primevue.config.locale.apply = "Применить";
    this.$primevue.config.locale.clear = "Сбросить";
  },
};
</script>

<style lang="scss">
.v-step {
  z-index: 10010 !important;
  @media (max-width: 768px) {
    display: none !important;
  }
}
</style>