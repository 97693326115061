import api from "@/api/index";
import store from "@/store";
import {
  setEmoji
} from "@/handlers/emoji";

export function getCompanyHome(that) {
  /*Получение и обработка списка карт*/
  api
    .get("/company/list")
    .then((r) => {
      console.info(r.statusText + " " + r.status + " " + setEmoji("access"));

      // r.data.forEach((i) => {
      //     that.companies.push(i);
      //     that.loading = false;
      // });
      that ? (that.companies = r.data) : null;
      // if (!store.state.curCompany) {
      //   if (that.onRowSelect) {
      //     // that.onRowSelect(that.companies[0]);
      //   }
      //   // that.cura[0] = that.companies[0];
      // } else {
      //   // const idCur = store.state.curCompany["id"];
      //   // const company = that.companies.find((e) => e.id == idCur);
      //   // store.commit("setCurCompany", company);
      //   // that.cura[0] = company;
      // }
      that ? (that.loading = false) : null;
    })
    .catch((err) => {
      console.log(err);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Неизвестная ошибка ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    });
}

export function getCompany(that) {
  /*Получение и обработка списка карт*/
  api
    .get("/company/list")
    .then((r) => {
      console.info(r.statusText + " " + r.status + " " + setEmoji("access"));
      that.fullCompanyList = r.data
    })
    .catch((err) => {
      console.log(err);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Неизвестная ошибка ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    }).finally(() => {
      that.loading = false;
    });;
}

export function getTransactions(that) {
  /*Получение и обработка списка транзакций*/
  api
    .get("trn_v1/list", {
      params: {
        dateStart: new Date().toISOString().split("T")[0] + "T00:00:00",
        dateEnd: new Date().toISOString().split("T")[0] + "T23:59:59",
        rows: 5,
        total_count: 5,
        page: 1,
        company: store.state.company.curCompany ?
          store.state.company.curCompany.id : null,
      },
    })
    .then((response) => {
      console.info(
        response.statusText + " " + response.status + " " + setEmoji("access")
      );

      that.trnList = response.data.result;
      that.totalCount =  response.data.total?.count
    })
    .catch((err) => {
      console.log(err);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Неизвестная ошибка ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    });
}


export function setStoreCompanyList() {
  return api
    .get("/company/list?mini")
    .then((r) => {
      console.info(r.statusText + " " + r.status + " " + setEmoji("access"));
      console.log(r.data.length);
      if (r.data.length) {
        if (
          JSON.stringify(store.state.company.companyList) !==
          JSON.stringify(r.data)
        ) {
          store.commit("setCompanyList", r.data)
        }

        if (
          store.state.company.curCompany == null ||
          store.state.company.curCompany == undefined || !store.state.company.curCompany
        ) {
          // ctx.commit("setCurCompany", r.data[0]);
          setStoreCurrentCompany(r.data[0]['id'])
        } else {
          if (
            JSON.stringify(store.state.company.curCompany) !==
            JSON.stringify(
              r.data.filter(
                (el) => el.id !== store.state.company.curCompany.id
              )[0]
            )
          ) {
            setStoreCurrentCompany(r.data.filter(
              (el) => el.id === store.state.company.curCompany.id
            )[0]['id'])
          }
        }
      } else {
        alert(`Нет компаний привязанных к аккаунту, обратитесь к Вашему менеджеру.`);
        let mainHref = document.location.href
        document.location.href = mainHref + 'login'
      }
    })
    .catch((err) => {
      console.log(err);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Неизвестная ошибка ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    }).finally(() => {});

}

export function setStoreCurrentCompany(id) {
  return api
    .get("/company/list", {
      params: {
        company: id,
      },
    })
    .then((r) => {
      console.info(r.statusText + " " + r.status + " " + setEmoji("access"));
      store.commit("setCurCompany", r.data[0])
    })
    .catch((err) => {
      console.log(err);
      let statusCode = err.request.status;
      let statusText = err.request.statusText;
      console.error(setEmoji("error") + statusCode + " " + statusText);
      switch (statusCode) {
        case 0:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("error")} Нет связи с сервером`,
            detail: "Попробуйте позднее!",
          });
          break;
        case 403:
          that.$toast.add({
            severity: "warn",
            summary: `${setEmoji("lock")} Время сессии истекло`,
            detail: "Требуется авторизация",
            life: 5000,
          });
          that.$router.push("/login");
          break;
        case 404:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Ошибка ${statusCode}`,
            detail: "Запрос не найден",
            life: 5000,
          });
          break;
        default:
          that.$toast.add({
            severity: "error",
            summary: `${setEmoji("error")} Неизвестная ошибка ${statusCode}`,
            detail: "Попробуйте позднее!",
            life: 5000,
          });
          break;
      }
    }).finally(() => {});
}