import {
  createStore
} from "vuex";
import createPersistedState from "vuex-persistedstate";
import company from "./modules/company";
import filials from "./modules/filials";
import news from "./modules/news";

export default createStore({
  state: {},
  stateUser: {
    curUser: null, // Выбранный пользователь
  },

  mutations: {
    setUser(stateUser, user) {
      stateUser.curUser = user;
    },
  },
  getters: {},
  actions: {},
  modules: {
    company,
    filials,
    news
  },
  plugins: [
    createPersistedState({
      storage: window.localStorage,
      paths: ["company.curCompany"],
      key: "__company",
    }),
  ],
});