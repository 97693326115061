<template>
  <div class="px-2 mt-1">
    <div
      class="shadow-3 border-round flex relative md:gap-4 gap-2 align-items-center lg:static justify-content-between gap-0"
      style="min-height: 42px"
    >
      <div class="flex w-4 flex-auto mx-2 py-2 fillter border-round">
        <FilterCompany class="bg-white w-full border-round" />
      </div>
      <div class="flex gap-2 lg:mr-0 mr- h-full">
        <div v-if="showReferal" class="flex h-full align-items-center "> 
          <PartnerReferal />
        </div>
        <div class="flex h-full align-items-center">
          <Button
            class="p-button-sm p-button-raised px-2 w-full"
            icon="pi pi-megaphone"
            iconPos="left"
            :badge="getCountNotification"
            badgeClass="p-badge-warning"
            v-tooltip.bottom="'Оповещение'"
            @click="() => $router.push('/news')"
          />
        </div>
        <!-- <div class="flex h-full align-items-center" v-tooltup="'Поддержка'">
          <Button
            class="p-button-sm p-button-outlined px-2 w-full"
            icon="pi pi-comments"
            iconPos="left"
            :badge="getCountNotification"
            badgeClass="p-badge-warning"
          />
        </div> -->
        <div
          class="hidden border-round lg:flex lg:justify-content-end absolute lg:static w-full lg:w-min ml-0 lg:mr-2 mr-0 left-0 top-100 z-1 shadow-2 lg:shadow-none bg-white"
        >
          <ul class="flex list-none p-0 m-0 flex-column lg:flex-row">
            <li class="h-full relative">
              <Button
                icon="pi pi-users"
                class="p-button-sm p-button-raised"
                :label="
                  $store.state.curUser
                    ? $store.state.curUser.phone
                    : '79999999999'
                "
                v-styleclass="{
                  selector: '@next',
                  enterClass: 'hidden',
                  enterActiveClass: 'scalein',
                  leaveToClass: 'hidden',
                  leaveActiveClass: 'fadeout',
                  hideOnOutsideClick: true,
                }"
              />
              <!-- <a
              v-ripple
              class="cursor-pointer h-full inline-flex align-items-center text-bluegray-800 py-3 lg:py-0 px-1 border-left-2 lg:border-left-none lg:border-bottom-2 border-transparent hover:border-500 transition-colors transition-duration-150 p-ripple"
              v-styleclass="{
                selector: '@next',
                enterClass: 'hidden',
                enterActiveClass: 'scalein',
                leaveToClass: 'hidden',
                leaveActiveClass: 'fadeout',
                hideOnOutsideClick: true,
              }"
            >
              <Avatar
                class="ava bg-black-alpha-90 text-primary p-badge-sm p-overlay-badge"
                shape="circle"
              >
                <div v-if="$store.state.curUser">
                  {{
                    $store.state.curUser
                      ? createName($store.state.curUser.user_name)
                      : "AA"
                  }}
                </div>
                <Skeleton
                  v-else
                  width="100%"
                  height="100%"
                  shape="circle"
                ></Skeleton>
              </Avatar>
              <div>
                <span
                  v-if="$store.state.curUser"
                  class="mx-2 font-medium text-bluegray-800"
                  >{{
                    $store.state.curUser
                      ? $store.state.curUser.phone
                      : "79999999999"
                  }}</span
                >
                <Skeleton
                  v-else
                  width="78px"
                  height="20px"
                  class="mx-2"
                ></Skeleton>
              </div>
              <i class="pi pi-angle-down"></i>
            </a> -->
              <div
                class="hidden static lg:absolute w-full bg-white left-0 top-100 shadow-none lg:shadow-2 origin-top border-round pl-3 lg:pl-0 bg-white"
              >
                <ul class="list-none p-0 m-0">
                  <li>
                    <router-link
                      to="/profile"
                      v-ripple
                      class="cursor-pointer h-full inline-flex align-items-center text-bluegray-800 border-left-2 border-transparent hover:border-500 transition-colors transition-duration-150 p-3 p-ripple"
                    >
                      <span class="pi pi-user mr-2"></span>
                      <span class="font-medium">Профиль </span>
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      v-ripple
                      to="/tools"
                      class="cursor-pointer h-full inline-flex align-items-center text-bluegray-800 border-left-2 border-transparent hover:border-500 transition-colors transition-duration-150 p-3 p-ripple"
                    >
                      <span class="pi pi-cog mr-2"></span>
                      <span class="font-medium">Настройки</span>
                    </router-link>
                  </li>
                  <li>
                    <a
                      @click="logout"
                      v-ripple
                      class="cursor-pointer h-full inline-flex align-items-center text-bluegray-800 border-left-2 border-transparent hover:border-500 transition-colors transition-duration-150 p-3 p-ripple"
                    >
                      <i class="pi pi-sign-out mr-2"></i>
                      <span class="font-medium">Выход</span>
                    </a>
                  </li>
                  <!-- <li class="bg-gray-200">
                  <div
                    class="p-3 flex gap-4 align-items-center justify-content-center"
                  >
                    <span class="text-lg">Снег</span>
                    <InputSwitch @change="toggleSnow" v-model="checked" />
                  </div>
                </li> -->
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getProfile } from "@/api/profile";
import FilterCompany from "../FilterCompany.vue";
import store from "@/store";
import { createProfileName } from "@/handlers/createProfileName";
import PartnerReferal from "@/components/PartnerReferal.vue";

export default {
  components: {
    FilterCompany,
    PartnerReferal,
  },
  data() {
    return {
      name: "Header",
      companies: [],
      profiles: null,
      selectedCompany: null,
      // checked: true,
    };
  },
  mounted() {
    // console.log(store.state.company.curCompany);
  },
  beforeCreate() {
    getProfile(this);
  },
  methods: {
    logout() {
      localStorage.removeItem("token");
      store.commit("setCurCompany", null);
      store.commit("setCompanyList", []);
      setTimeout(() => {
        this.$router.push("/login");
      }, 50);
    },
    toggle(event) {
      this.$refs.op.toggle(event);
    },
    createName(value) {
      return createProfileName(value);
    },
    toggleSnow() {
      if (this.checked) {
        document.querySelector(".snowmode").classList.add("snow");
        localStorage.setItem("snowmode", true);
      } else {
        document.querySelector(".snowmode").classList.remove("snow");
        localStorage.setItem("snowmode", false);
      }
    },
  },
  computed: {
    getCountNotification() {
      return this.$store.state.news.countNews;
    },
    showReferal() {
      return window.filial == "expcard"
    }
  },
  mounted() {
    console.log(this.$route.path);
    // console.log(1, JSON.parse(localStorage.getItem("snowmode")));
    // if (localStorage.getItem("snowmode") === null) {
    //   this.checked = true;
    //   this.toggleSnow();
    // } else {
    //   if (JSON.parse(localStorage.getItem("snowmode"))) {
    //     this.checked = true;
    //   } else {
    //     this.checked = false;
    //   }
    //   this.toggleSnow();
    // }
  },
  components: { FilterCompany },
};
</script>

<style lang="scss">
a {
  text-decoration: none;
}

.hover\:border-500:hover {
  border-color: transparent !important;
}

// a[aria-current="page"] {
//   &:hover {
//     border: none !important;
//   }

//   border: none !important;
// }
// .profile-btn {
//   display: inline-flex;
//   @media (max-width: 768px) {
//     display: none;
//   }
// }
.fillter {
  & .p-component {
    font-size: 0.8rem !important;
    @media (max-width: 1440px) {
      font-size: 1rem !important;
    }
  }

  .p-inputtext {
    padding: 0.6rem 0.5rem;
  }
}
.ava {
  span {
    margin-top: -8px;
  }
}
</style>
