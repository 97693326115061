import api from "@/api/index";
import {
    setEmoji
} from "@/handlers/emoji";
import store from "@/store";

export function getFilialSecure() {
    api
        .get("user/filial", {
            params: {
                phone: store.state.curUser.phone,
            },
        })
        .then((r) => {
            console.info(r.statusText + " " + r.status + " " + setEmoji("access"));
            console.log(r.data.find(el => el.code === window.filial));
            let filial = r.data.find(el => el.code === window.filial)
            store.commit('setFilialInfo', filial !== undefined ? filial : {})
            console.log(store.state);
        })
        .catch((err) => {
            console.log(err);
            let statusCode = err.request.status;
            let statusText = err.request.statusText;
            console.error(setEmoji("error") + statusCode + " " + statusText);
            switch (statusCode) {
                case 0:
                    that.$toast.add({
                        severity: "warn",
                        summary: `${setEmoji("error")} Нет связи с сервером`,
                        detail: "Попробуйте позднее!",
                    });
                    break;
                case 403:
                    that.$toast.add({
                        severity: "warn",
                        summary: `${setEmoji("lock")} Время сессии истекло`,
                        detail: "Требуется авторизация",
                        life: 5000,
                    });
                    that.$router.push("/login");
                    break;
                case 404:
                    that.$toast.add({
                        severity: "error",
                        summary: `${setEmoji("error")} Ошибка ${statusCode}`,
                        detail: "Запрос не найден",
                        life: 5000,
                    });
                    break;
                default:
                    that.$toast.add({
                        severity: "error",
                        summary: `${setEmoji("error")} Неизвестная ошибка ${statusCode}`,
                        detail: "Попробуйте позднее!",
                        life: 5000,
                    });
                    break;
            }
        })
}
export function getFilialOpen(that) {
    let filial = window.filial
    api
        .get("user/open_filial_info", {
            params: {
                filial,
            },
        })
        .then((r) => {
            console.info(r.statusText + " " + r.status + " " + setEmoji("access"));
            console.log(r);
            // let filial = r.data.find(el => el.code === window.filial)
            // store.commit('setFilialInfo', filial !== undefined ? filial : {})
            // console.log(store.state);
        })
        .catch((err) => {
            console.log(err);
            let statusCode = err.request.status;
            let statusText = err.request.statusText;
            console.error(setEmoji("error") + statusCode + " " + statusText);
            switch (statusCode) {
                case 0:
                    that.$toast.add({
                        severity: "warn",
                        summary: `${setEmoji("error")} Нет связи с сервером`,
                        detail: "Попробуйте позднее!",
                    });
                    break;
                case 403:
                    that.$toast.add({
                        severity: "warn",
                        summary: `${setEmoji("lock")} Время сессии истекло`,
                        detail: "Требуется авторизация",
                        life: 5000,
                    });
                    that.$router.push("/login");
                    break;
                case 404:
                    that.$toast.add({
                        severity: "error",
                        summary: `${setEmoji("error")} Ошибка ${statusCode}`,
                        detail: "Запрос не найден",
                        life: 5000,
                    });
                    break;
                default:
                    that.$toast.add({
                        severity: "error",
                        summary: `${setEmoji("error")} Неизвестная ошибка ${statusCode}`,
                        detail: "Попробуйте позднее!",
                        life: 5000,
                    });
                    break;
            }
        })
}